.wrapper {
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #110a1924;
}

.modalContainer {
  background-color: white;
  padding: 30px 50px;
  box-shadow: 0 2px 14px rgba(6, 5, 44, 0.1);
  border: 1px solid #14B5B1;
  border-radius: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 101;
  transform: translate(-50%,-50%);
}

.flex {
  display: flex;
}

.modalHeader {
  align-items: center;
  background: red;
  border-bottom: 1px solid black;
  padding: 12px 24px;
}

.modalTitle {
  align-items: center;
  flex: 1;
}

.modalBody {
  flex-direction: column;
  height: 100%;
  padding: 24px;
}

.modalTransition {
  transition: 0.3s all ease-out;
  visibility: hidden;
  opacity: 0;
}

.show {
  opacity: 1;
  visibility: visible;
}
