
@use "helper/style/mixin";
@use "helper/style/media";

@include mixin.until(media.$md) {
  .dark {
    --text: 255, 255, 255;
    --bg: 0, 0, 0;
    --pink: 56, 215, 213;

    --success: 13, 208, 21;
    --announce: 59, 153, 252;
    --error: 153, 153, 153;
  }

  .light {
    --text: 0, 0, 0;
    --bg: 255, 255, 255;
    --pink: 56, 215, 213;

    --success: 13, 208, 21;
    --announce: 59, 153, 252;
    --error: 153, 153, 153;
  }

  .transparent {
    --text: 0, 0, 0;
    --bg: 255, 255, 255;
    --pink: 56, 215, 213;

    --success: 13, 208, 21;
    --announce: 59, 153, 252;
    --error: 153, 153, 153;
  }
}
