@use "../helper/style/mixin";
@use "../helper/style/media";

html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;

  @include mixin.between(media.$md, media.$xl) {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &::-moz-focus-inner {
    border: 0;
  }
}
